import {get, post} from "@/utils/request";
import {FileInfoVo, InPageInfo, MeaasgeParam, ProductsInfoVO, ProductsSearchDto} from '@/service/interface';

const commonUrl = process.env.REACT_APP_URL;

/**
 * 数据产品分页查询
 * @param data 参数
 */
export function getProductPage(type: string, data: ProductsSearchDto) {
  return post<InPageInfo<ProductsInfoVO>>(`${commonUrl}/yy-${type}-product/page`, {data});
}

/**
 * 数据产品查询详情
 * @param id id
 */
export function getProductById(type: string, data: { id: string }) {
  return post<ProductsInfoVO>(`${commonUrl}/yy-${type}-product/getById`, {params: data});
}

/**
 * 应用产品查询附件列表
 * @param id id
 */
export function getProductAttachmentList(id: string) {
  return get<FileInfoVo[]>(`${commonUrl}/yy-application-product/attachmentList/${id}`);
}

/**
 * 数据产品热门搜索
 */
export function getProducthotSearch(type: string) {
  return post<string[]>(`${commonUrl}/yy-${type}-product/hotSearch`, {});
}

/**
 * 申请使用/留言咨询
 * @param data 表单信息
 */
export function postApplicationConsult(data: MeaasgeParam) {
  return post<boolean>(`${commonUrl}/yy-api-product/application`, {data});
}

/** apiProductPage-api产品 获取分页数据-请求参数  */
export interface ApiProductPageParams {
  /**  0非热门，1热门  */
  isHot?: number;
  name?: string;
  pageNo: number;
  pageSize: number;
  /**  0自研，1展销  */
  salesType?: number | "";
  scene?: string;
}

/**
 * @desc api产品 获取分页数据
 * @param data
 */
export function apiProductPage(data: ApiProductPageParams) {
  return post(`${commonUrl}/yy-api-product-new/page`, {
    data
  })
}

/**
 * @desc api产品 获取分页数据
 * @param data
 */
export function apiProductPageOld(data: ApiProductPageParams) {
  return post(`${commonUrl}/yy-api-product/page`, {
    data
  })
}


/** api产品 首页热门api  */
export function apiProductHotHome(isIndex: boolean) {
  return get(`${commonUrl}/yy-api-product-new/homeApi/${isIndex}`)
}

/** api产品 首页热门api 老版本  */
export function apiProductHotHomeOld() {
  return get(`${commonUrl}/yy-api-product/homeApi`)
}

/**
 * @desc api产品 详情
 * @param id
 */
export function apiProductDetail(id: string) {
  return get(`${commonUrl}/yy-api-product-new/view/product/${id}`)
}

/** api产品 接口信息  */
export function apiProductInterfaceInfo(productOperateId: string,productId: string) {
  return get(`${commonUrl}/yy-api-product-new/view/product/switch/${productOperateId}/${productId}`)
}

/**
 * 获取平台公共请求参数模板参数列表
 */
export function getCommonParams(params?: any) {
  return get(`${commonUrl}/product-api-param-template/getCommonParams`, {
    //params: params,
    //data: params,
  });
}

/**
 * 生成pdf
 */
export function generatePdf(id: string) {
  return get(`${commonUrl}/product-operate/generate-pdf/${id}`, {
    responseType: "blob",
  });
}

/** 获取授权参数模板  */
export function getTemplateInfo(id: string) {
  return get(`${commonUrl}/auth/getTemplateInfo/${id}`)
}