import {get, post} from "@/utils/request";

const commonUrl = process.env.REACT_APP_URL;

/**
 * @desc 服务中心-企业认证
 */


/**
 * @desc 企业认证-初次认证
 */
export function saveCertification(data: any) {
  return post(`${commonUrl}/customer-auth-record/certification`, {
    data,
  })
}

/**
 * @desc 查询生态伙伴认证详情
 * @param id
 */
export function getCustomerById(id: string) {
  return get(`${commonUrl}/customer-auth-record/view/${id}`)
}

/**
 * @desc 获取历史认证记录 分页接口
 * @param params
 */
export function getAuthRecordPage(params: any) {
  return get(`${commonUrl}/customer-auth-record/page`, {
    params: {...params, createTime: undefined},
  })
}

/**
 * @desc 重新认证详情
 * @param params
 */
export function getReAuthDetail(params: unknown) {
  return get(`${commonUrl}/customer-auth-record/certification/view`, {
    params
  })
}

/**
 * @desc 通过Id查询认证详情
 * @param id
 */
export function getAuthDetailById(id: string) {
  return get(`${commonUrl}/customer-auth-record/view/${id}`)
}

/**
 * @desc 获取生态伙伴协议
 * @param partnerTypeCode
 */
export function getPartnerTypeAgreement(partnerTypeCode: number) {
  return get(`${commonUrl}/yy-agreement/partner/sign/${partnerTypeCode}`)
}

/**
 * @desc 获取历史签署协议
 * @param data
 */
export function getPartnerSignHistory(data: any) {
  return post(`${commonUrl}/customer-protocol-sign/partner/page`, {
    data: {...data, signTime: undefined},
  })
}

/**
 * @desc 获取签署的协议详情
 * @param id
 */
export function getSignAgreementDetail(id: string) {
  return get(`${commonUrl}/yy-agreement/view/${id}`)
}

/**
 * @desc 获取客户的认证身份
 */
export function getCustomerAuthList() {
  return get(`${commonUrl}/customer/getCustomerAuthList`)
}

/**
 * @desc 获取所有的平台协议
 */
export function getAllAgreement() {
  return get(`${commonUrl}/yy-agreement/partner/sign`)
}

/**
 * @desc 查询生态伙伴历史认证通过信息
 * @param partnerType
 */
 export function getCustomerByPartnerType(partnerType: any) {
  return get(`${commonUrl}/customer-auth-record/certification/${partnerType}/view`)
}