import React, {useEffect, useState} from 'react';
import {MenuProps, message} from 'antd';
import {Menu} from 'antd';
import {menuList} from '@/router/menu';
import {useLocation, useNavigate} from 'react-router-dom'
import ProductTab from './ProductTab';

import styles from './index.module.less';
import {tree2list} from "@/utils/file";

type MenuItem = Required<MenuProps>['items'][number];

const navList = JSON.parse(sessionStorage.getItem("NAV_LIST") || "[]")

/** 根据Id 查询配置 url   */
export function searchConfigUrl(id: string, navList) {
  const list = tree2list(navList || []);
  const item = list?.find(item => item.id === id)
  if (!item) {
    return '';
  }
  return item?.detail?.data?.url
}

/** 根据Id 查询配置 是否需要隐藏   */
export function searchConfigToHidden(id: string, navList) {
  const list = tree2list(navList || []);
  const item = list?.find(item => item.id === id)
  if (!item) {
    return false;
  }
  return item?.detail?.data?.status === 1
}

/** 根据Id 查询配置 系统预制路由 是否有标签（无0，1最新，2最热）  */
function searchConfigToTag(id: string, navList): "0" | "1" | "2" {
  const list = tree2list(navList || [])
  const item = list?.find(item => item.id === id)
  if (!item) {
    return "0";
  }
  return item?.detail?.data?.showLabel
}

/**
 * @desc 根据Id 查询配置 系统预制路由 的 名称和path
 * @param id
 * @param navList
 * @param field
 * @param originField
 */
export function searchConfigNameOrPath(id: string, navList: any[], field: "name" | "url", originField: string) {
  const list = tree2list(navList || [])
  const item = list?.find(item => item.id === id)

  // 如果没有 则使用前端 自己的数据
  if (!item) {
    return originField
  }
  return item?.detail?.data[field]
}

function getItem(
  label: React.ReactNode,
  key: React.Key,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    children,
    label,
    type,
  } as MenuItem;
}

const getIcons = (name: string) => {
  return require(`@/assets/dataProducts/${name}.png`);
};

const getItems = (selectedKeys: string[], navList: any[]): MenuItem[] => {
  const arr = menuList
    ?.filter(item => {
      const configUrl=searchConfigUrl(item.id, navList); 
      if(configUrl.startsWith("http")){
        item.path=configUrl;
      }
      return searchConfigToHidden(item.id, navList) && item?.path;
    })
    .map(menu => {
      if (menu.path === '/products') {
        return {
          key: searchConfigNameOrPath(menu?.id, navList, "url", menu.path),
          label: searchConfigNameOrPath(menu?.id, navList, "name", menu.name),
          children: [{
            type: 'group',
            label: (
              <ProductTab
                selectedKey={selectedKeys.length > 1 ? selectedKeys[1] : selectedKeys[0]}
                navList={navList}
                // @ts-ignore
                routes={menu.children
                  ?.filter(item => {
                    //由后台配置是否显示菜单
                    const isShowMenu=searchConfigToHidden(item?.id, navList);
                    return !item?.hidden && item?.path&&isShowMenu;
                  })
                  ?.map(routeMenu => {
                    return getItem(searchConfigNameOrPath(routeMenu?.id, navList, "name", routeMenu.name), routeMenu.path);
                  }) || []
                }
              />
            )
          }],
        }
      }
      if (menu?.children) {
        return {
          title: "111",
          key: menu?.path,
          icon: menu?.icon,
          label: <div className={styles.menu_item}>
            <span>{searchConfigNameOrPath(menu?.id, navList, "name", menu.name)}</span>
            {searchConfigToTag(menu?.id, navList) === "1" &&
              <div className={styles.label_tag}><img src={getIcons("api_hot_icon")} alt="热门"/></div>}
            {searchConfigToTag(menu?.id, navList) === "2" &&
              <div className={styles.label_tag}><img src={getIcons("api_new_icon")} alt="最新"/></div>}
          </div>,
          children: menu.children
            .filter(routeMenu => {
              //赋值url
              const configUrl=searchConfigUrl(routeMenu?.id, navList); 
              if(configUrl.startsWith("http")){
                routeMenu.path=configUrl;
              }
              //由后台配置是否显示菜单
              const isShowMenu=searchConfigToHidden(routeMenu?.id, navList);
              return !routeMenu?.hidden && routeMenu?.path&&isShowMenu
            })
            .map(routeMenu => getItem(searchConfigNameOrPath(routeMenu?.id, navList, "name", routeMenu.name), routeMenu.path))
        }
      } else {
        return {
          title: searchConfigNameOrPath(menu?.id, navList, "name", menu.name),
          key: menu?.path,
          path: searchConfigNameOrPath(menu?.id, navList, "url", menu.path),
          label: <div className={styles.menu_item}>
            <span>{searchConfigNameOrPath(menu?.id, navList, "name", menu.name)}</span>
            {searchConfigToTag(menu?.id, navList) === "1" &&
              <div className={styles.label_tag}><img src={getIcons("api_hot_icon")} alt="热门"/></div>}
            {searchConfigToTag(menu?.id, navList) === "2" &&
              <div className={styles.label_tag}><img src={getIcons("api_new_icon")} alt="最新"/></div>}
          </div>,
        };
      }
    });
  return arr;
};

/** 根据url 获取 对应 的权重字段的值  */
export function getWeight(key: string, navList) {
  const list = tree2list(navList);
  const i = list.find(item => item?.detail?.data?.url === key);
  return i?.detail?.data?.weight || 9999;
}


// 递归函数实现对每一层的排序
export function sortNestedArrayByWeight(arr: any[]) {
  /** 判断是否为数组  */
  if (!Array.isArray(arr)) {
    return arr;
  }
  arr.forEach(innerArr => {
    sortNestedArrayByWeight(innerArr?.children)
  })
  arr.sort((a: any, b: any) => {
    if (a.weight < b.weight) {
      return -1
    } else if (a.weight > b.weight) {
      return 1
    } else {
      return 0
    }
  })
  return arr;
}

/** 给菜单里面的路由加 权重字段  */
export function setWeightField(arr: any[], navList) {
  if (!arr) return [];
  return arr.map((item) => {
    const {children} = item;
    if (children && children.length > 0) {
      return {
        ...item,
        weight: getWeight(item.key, navList),
        children: setWeightField(children, navList),
      };
    }
    return {
      ...item,
      weight: getWeight(item.key, navList),
    };
  });
}


const MyMenu = (props: any) => {
  const navigate = useNavigate()
  // 获取当前路径
  const {pathname} = useLocation()
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const [openKeys, setOpenKeys] = React.useState<string[]>([]);
  const [path, setPath] = useState('');


  useEffect(() => {
    //获取当前所在的目录层级
    const rank = pathname.split('/');
    switch (rank.length) {
      case 2: //一级目录
        setSelectedKeys([pathname]);
        break;
      case 3: //二级目录，要展开一个subMenu
        setOpenKeys([rank.slice(0, 2).join('/')]);
        setSelectedKeys([rank.slice(0, 2).join('/'), pathname]);
        break;
      default:
        setSelectedKeys([rank.slice(0, 2).join('/'), pathname]);
        break;
    }
  }, [pathname]);

  const rootSubmenuKeys = menuList
    ?.filter(item => !item?.hidden)
    .map(menu => menu.path);

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  const onSelect: MenuProps['onSelect'] = ({key}) => {
    setSelectedKeys([key]);
  };

  /** 根据点击的url判断 是否 是新标签打开  */
  function openNewByUrlConfig(url: string) {
    const list = tree2list(navList)
    const item = list.find(item => item?.detail?.data?.url === url);
    if (!item) {
      return false;
    }
    return item?.detail?.data?.newOpen
  }

  /** 根据点击的url判断 是否 有菜单权限  */
  function getMenuPermissionByUrl(url: string,navList) {
    const list = tree2list(navList)
    const item = list.find(item => item?.detail?.data?.url === url);
    if (!item) {
      return false;
    }
    return item?.detail?.data?.hasPermissions;
  }

  //获取菜单权限
  const getMenuPermission=async (url:string)=>{
    // console.log(props.navList);
    return getMenuPermissionByUrl(url,props.navList);
  }

  const menuConfig: MenuProps = {
    mode: 'horizontal',
    theme: 'light',
    style: {
      fontFamily: `"Helvetica Neue", Helvetica, "PingFang SC","Hiragino Sans GB", "Microsoft YaHei", Arial,sans-serif`,
      fontWeight: 800,
      border: 'none',
      background: 'transparent'
    },
    onClick: async ({key, ...props}) => {
      console.log('navigate',key);
      //判断菜单的登录权限
      const isLogin = sessionStorage.getItem('token');
      //数据标注平台
      if(key.includes('/external/platform')){
        if(!isLogin) {
          navigate('/login');
          return ;
        };

        const permission=await getMenuPermission(key);
        if(!permission){
          message.destroy();
          message.warning("没有访问权限,请联系平台开通！");
          return ;
        }
      }
      if (key.startsWith("http")) {
        window.open(key);
        return;
      }
      if (openNewByUrlConfig(key)) {
        window.open(window.location.origin + "#" + key);
        return;
      }
      setPath(key);
      navigate(key);
    },
  };


  return (
    <Menu
      className={styles.menu}
      {...menuConfig}
      // subMenuCloseDelay={100}
      // openKeys={openKeys}
      // onOpenChange={onOpenChange}
      selectedKeys={selectedKeys}
      onSelect={onSelect}
      expandIcon={null}
      items={sortNestedArrayByWeight(setWeightField(getItems(selectedKeys, props.navList), props.navList))}
    />
  );
};
export default MyMenu;
