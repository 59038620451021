import type { AxiosRequestConfig } from "axios";
import axios from "axios";
import ReactDOM from "react-dom";
import qs from "qs";
import { message, Spin } from "antd";
import React from "react";
import storeService from "@/service/store.service";
import messageCenter from "@/utils/msg";

export enum ServerTypes {
  defualt = "iam-business-service",
  serviceinfo = "iam-b2b-service",
}

//  设置请求超时时间和域名
axios.defaults.baseURL = storeService.getBaseUrl();
axios.defaults.timeout = 10000;
axios.defaults.withCredentials = true;

interface RequestConfig extends AxiosRequestConfig {
  baseurlType?: string;
  config?: any;
  serverType?: any;
  loading?: boolean;
  useSelfDead?: any;
  headers?: any;
  skipError?: boolean;
}

interface Params {
  params?: any;
  data?: any;
  loading?: boolean;
  serverType?: ServerTypes;
}

// 当前正在请求的数量
let requestCount = 0;

// 显示loading
function showLoading() {
  if (requestCount === 0) {
    const dom = document.createElement("div");
    dom.setAttribute("id", "loading");
    document.body.appendChild(dom);
    let node = <Spin tip="加载中..." size="large" />;
    ReactDOM.render(node, dom);
  }
  requestCount++;
}

// 隐藏loading
function hideLoading() {
  requestCount > 0 && requestCount--;
  if (requestCount === 0 && document.getElementById("loading")) {
    document.body.removeChild(document.getElementById("loading") as Node);
  }
}

//  http request 拦截器
axios.interceptors.request.use(
  (config: RequestConfig): RequestConfig => {
    const { loading } = config;
    if (loading) {
      showLoading();
    }
    if (!config.useSelfDead) {
      config.headers["Content-Type"] = "application/json;charset=UTF-8";
    }

    let authorization = storeService.getToken();
    if (authorization) {
      config.headers["s-token-id"] = authorization;
    }
    config.headers["source"] = "element";
    return config;
  },
  (err) => {
    if (err.config.headers.isLoading !== false) {
      hideLoading();
    }
    return Promise.reject(err);
  }
);
//  响应拦截器即异常处理
axios.interceptors.response.use(
  (res: any) => {
    hideLoading();
    let response = res.data;
    // console.log("response:", response);
    // response.code = Number(response?.code);
    if (response.code == 200) {
      return Promise.resolve(response && response.content);
    } else if (response.code == 302) {
      return Promise.reject(res);
    } else if (response.code == 401 || response.code === "401") {
      messageCenter?.repeatLogin();
      window.open("/#/login", "_self");
      return Promise.reject(res);
    } else if (res.status === 200 && response.code && response?.code != 200) {
      if (!res?.config?.skipError) {
        message.error(response.msg);
      }
      return Promise.reject(res);
    } else if (res.status === 200) {
      return Promise.resolve(res);
    } else {
      return Promise.reject(res);
    }
  },
  (error: any) => {
    console.log("####error", error);
    hideLoading();
    if (error && error.response && error.response.data) {
      let data = error.response.data;
      console.log("data:", data);
      if (data.code === 401 || data.code === "401") {
        window.open("/#/login", "_self");
      } else {
        message.error(
          error.response.data.msg ? error.response.data.msg : "错误"
        );
      }
    }

    return Promise.reject(error.response);
  }
);

/**
 * 封装get方法
 * @param url
 * @param config
 * @returns {Promise}
 */
function get<T = any>(url: string, config?: RequestConfig): Promise<T> {
  return new Promise((resolve: any, reject) => {
    axios
      .get(url, {
        ...config,
        // 手动将数组序列化的模式设置为repeat
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param config
 * @returns {Promise}
 */
function post<T = any>(url: string, config: RequestConfig): Promise<T> {
  const { data, ...rest } = config;
  return new Promise((resolve: any, reject) => {
    axios
      .post(url, data, {
        ...rest,
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      })
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
  });
}

/**
 * 封装patch请求
 * @param url
 * @param config
 * @returns {Promise}
 */
function patch<T = any>(url: string, config: Params): Promise<T> {
  return new Promise((resolve: any, reject) => {
    axios.patch(url, config).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * 封装put请求
 * @param url
 * @param config
 * @returns {Promise}
 */
function put<T = any>(url: string, config: Params): Promise<T> {
  const { data, ...rest } = config;
  return new Promise((resolve: any, reject) => {
    axios
      .put(url, data, {
        ...rest,
        // 手动将数组序列化的模式设置为repeat
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      })
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
  });
}

/**
 * 封装delete请求
 * @param url
 * @param config
 * @returns {Promise}
 */
function del<T = any>(url: string, config?: Params): Promise<T> {
  return new Promise((resolve: any, reject) => {
    axios
      .delete(url, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//  开放接口
export { get, post, put, patch, del };
export default axios;
