import { Form, FormInstance, Input, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { CompassOutlined } from "@ant-design/icons";
import AMapLoader from "@amap/amap-jsapi-loader";
import styles from "./index.module.less";

interface FormItemModalMapProps {
  label: string;
  name: string;
  rules?: any[];
  form?: FormInstance<any>;
  labelCol?: any;
  wrapperCol?: any;
}

/**
 * @Author wangyubo
 * @Date 2024/2/2
 * @Path src/components/FormItemModalMap
 * @IDE WebStorm
 * @Desc index
 * */
export function useFormItemModalMap(props: FormItemModalMapProps) {
  const {
    form,
    label = "地址",
    name = "address",
    rules,
    labelCol,
    wrapperCol,
  } = props;
  /**  */
  const [open, setOpen] = useState(false);
  const mapRef = useRef<HTMLDivElement>(null);
  /** 解析后的地址 */
  const [address, setAddress] = useState<string | undefined>();

  let map: any = null;
  let marker: any = null;
  let geocoder: any = null;
  let autoOptions = {
    input: "tipinput",
  };
  let auto = null;
  let placeSearch: any = null;
  /** 47c93ff8fd83e6be9bd1609e96c686a4  */
  /** 7bdd64a3c58c44577dc5fa95aed07f8e  */
  /** 567edea82000a8e8f230e4635b57249f  */
  useEffect(() => {
    if (open) {
      /** 如果地图已存在   */
      if (map) return;
      AMapLoader.load({
        key: "567edea82000a8e8f230e4635b57249f",
        version: "2.0",
      })
        .then((AMap) => {
          map = new AMap.Map("map_warp", {
            viewMode: "3D",
            zoom: 13,
          });
          marker = new AMap.Marker();
          AMap.plugin(
            ["AMap.Geocoder", "AMap.PlaceSearch", "AMap.AutoComplete", ""],
            () => {
              geocoder = new AMap.Geocoder();
              auto = new AMap.AutoComplete(autoOptions);
              placeSearch = new AMap.PlaceSearch({ map });
              // @ts-ignore
              auto.on("select", handleSelect);
            }
          );
          map.on("click", handleMapClick);
          map.add(marker);
        })
        .catch((e) => console.log(e));
    }
    return () => {
      map?.destroy();
    };
  }, [open]);

  function handleSelect(e: any) {
    placeSearch?.setCity(e?.poi?.adcode);
    placeSearch?.search(e?.poi?.name);
  }

  /** 地图双击  */
  function handleMapClick(e: any) {
    const lng = e.lnglat.getLng();
    const lat = e.lnglat.getLat();
    marker.setPosition([lng, lat]);
    geocoder.getAddress([lng, lat], (status: unknown, result: any) => {
      if (status === "complete") {
        setAddress(result?.regeocode?.formattedAddress);
      }
    });
  }

  const jsx = (
    <>
      <Form.Item
        label={label}
        name={name}
        rules={rules}
        labelCol={labelCol}
        wrapperCol={wrapperCol}
      >
        <Input
          prefix={<CompassOutlined />}
          maxLength={100}
          addonAfter={<span onClick={() => setOpen(true)}>选择地址</span>}
        ></Input>
      </Form.Item>
      <Modal
        title={"选择地址"}
        open={open}
        width={800}
        onCancel={() => setOpen(false)}
        onOk={() => {
          form?.setFieldValue(name, address);
          setOpen(false);
        }}
      >
        <div className={styles.checkedAddressWarp}>
          <p className={styles.placeholderText}>点击地图选择地址</p>
          {address && (
            <p>
              <CompassOutlined /> <span>{address}</span>
            </p>
          )}
        </div>
        <Input placeholder={"请输入地址"} id={"tipinput"}></Input>
        <div
          id={"map_warp"}
          style={{ width: "100%", height: "32.5rem", marginTop: "1.25rem" }}
          ref={mapRef}
        ></div>
      </Modal>
    </>
  );
  return { jsx, address };
}
