import React from "react";
import ReactDOM from "react-dom";
import { Inspector } from "react-dev-inspector";
import {
  legacyLogicalPropertiesTransformer,
  px2remTransformer,
  StyleProvider,
} from "@ant-design/cssinjs";
import "@/styles/index.less";
import App from "./App";
import "./assets";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";
import { HashRouter } from "react-router-dom";
import zhCN from "antd/es/locale/zh_CN";
import "moment/locale/zh-cn";
import "@szl/directives";
import "@/styles/lc_icon.css";
import "@/styles/font.css";
import "./public-path";

// qiankun代码
declare const window: {
  __POWERED_BY_QIANKUN__: boolean;
  isDev: boolean;
  _AMapSecurityConfig: any;
};
window._AMapSecurityConfig = {
  securityJsCode: "4daff35697f0a4e4462dcc64dccab62e",
};

interface MountProps {
  identity?: string;
  container?: Element | Document;
}

let render: (props: MountProps) => void;
const px2rem = px2remTransformer({
  rootValue: 16, // 2rem = 1rem; @default 16
});

export async function bootstrap() {
  console.log("[react16] react app bootstraped");
}

// @ts-ignore
export async function mount(props) {
  render(props);
}

// @ts-ignore
export async function unmount(props) {
  const { container } = props;
  // @ts-ignore
  ReactDOM.unmountComponentAtNode(
    container
      ? container.querySelector("#root")
      : document.querySelector("#root")
  );
}

if (!window.__POWERED_BY_QIANKUN__) {
  window.isDev = true;
  ReactDOM.render(
    <ConfigProvider locale={zhCN}>
      <Inspector
        keys={["control", "shift", "command", "c"]}
        disableLaunchEditor={false}
      >
        <HashRouter>
          <StyleProvider
            transformers={[legacyLogicalPropertiesTransformer, px2rem]}
          >
            <App />
          </StyleProvider>
        </HashRouter>
      </Inspector>
    </ConfigProvider>,
    document.getElementById("root")
  );
} else {
  render = function (props: MountProps) {
    const { container } = props;
    const appKey = props.identity || sessionStorage.getItem("identity") || "";
    ReactDOM.render(
      // @ts-ignore
      <ConfigProvider
        locale={zhCN}
        getPopupContainer={() => {
          return (container as HTMLDivElement) || document.body;
        }}
      >
        <HashRouter basename={`/${appKey}`}>
          <StyleProvider
            transformers={[legacyLogicalPropertiesTransformer, px2rem]}
          >
            <App />
          </StyleProvider>
        </HashRouter>
      </ConfigProvider>,
      container
        ? container.querySelector("#root")!
        : (document.querySelector("#root")! as HTMLElement)
    );
  };
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
