import {get, post} from "@/utils/request";

const commonUrl = process.env.REACT_APP_URL;

/**
 * 首页热门api
 */
export function getHomeApi() {
  return get(`${commonUrl}/yy-api-product/homeApi`, {})
}

/**
 * 首页热门应用
 */
export function getHomeApply() {
  return get(`${commonUrl}/yy-application-product/homeApplication`, {})
}

/**
 * 首页置顶-新闻资讯
 */
export function getNews(data: any) {
  return post(`${commonUrl}/yy-article/home`, {data})
}

/**
 * @desc 获取合作伙伴
 */
export function getPartnerList() {
  return post(`${commonUrl}/dig-business-eco/list`, {
    data: {
      statusList: ["1"],
      publishPortal: "1",
      orders: [
        {
          "asc": false,
          "column": "weight"
        }
      ]
    }
  })
}

// 获取数据集 热门
export function getHotDataSet() {
  return get(`${commonUrl}/api/dataset/homeApplication`, {})
}

// 获取模型产品热门
export function getHotModelProduct() {
  return post(`${commonUrl}/model-product/modelProductPage`, {
    data:{
      pageSize: 4,
      pageNo: 1,
      isHot:true,
    }
  })
}

/** 获取首页轮播图  */
export function getHomeBanner() {
  return post(`${commonUrl}/pic-banner-portal/getManageBannerPic`, {
    data: {
      pageSize: 100,
      pageNo: 1,
      source: "PORTAL"
    }
  })
}