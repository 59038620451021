import { get, post } from "@/utils/request";
import { InMessageType, InSceneType, MeaasgeParam } from "./interface";

const commonUrl = process.env.REACT_APP_URL;


const devUploadPath = `${commonUrl}/minio/file-portal/miniIoUploadFile`
const proUploadPath = '/xs-api/data-element-portal/minio/file-portal/miniIoUploadFile'

// const devthumbnailImgPath  = `${commonUrl}/miniIOPreview/thumbnail?filePath=yypt/f53afc0f-8ce3-451f-8380-c9de806f37bc_首页图2.png&width=1920&height=700`

/**
 * 留言咨询-问题类型(数列表)
 */
export function getconsultationType() {
  return get<InMessageType[]>(`${commonUrl}/yy-consultation-type/tree`);
}

/**
 * 申请使用/留言咨询
 * @param data 表单信息
 */
export function creatConsultationMeaasge(data: any) {
  return post<boolean>(`${commonUrl}/yy-consultation-message/create`, { data });
}
/**
 * 新增产品申请
 * @param data 表单信息 

 */
export function creatProductApplication(data: MeaasgeParam) {
  return post<boolean>(`${commonUrl}/yy-product-apply/create`, { data });
}

/**
 * 场景枚举
 */
export function getScene() {
  return get<InSceneType[]>(`${commonUrl}/yy-api-product/getScene`);
}

/**
 * 应用场景
 */
export function getDomainList() {
  return get<any[]>(`${commonUrl}/yy-application-product/domainList`);
}

/** 获取数商列表  */
export function getDigBusinessPage(data: any) {
  return post(`${commonUrl}/dig-business-eco/page`, { data })
}

/** miniIOd单文件上传地址  */
export const MINIIO_UPLOAD_PATH = process.env.NODE_ENV === "development" ? devUploadPath : proUploadPath

/** miniIO文件预览地址  */
export const MINIIO_PREVIEW_PATH = `${commonUrl}/minio/file-portal/miniIOPreview`
export const MINIIO_PREVIEW_PATH_PIC = `${commonUrl}/minio/file-portal/miniIOPreview?filePath=`

/** 获取miniIo 缩略图的预览地址  */
export const MINIIO_SMALL_PREVIEW_PATH = `${commonUrl}/minio/file-portal/miniIOPreview/thumbnail?filePath=`

/**
 * @desc miniIo  单文件上传
 * @param data FormData; file：File； basePath："test"
 */
export function miniIoUpload(data: any) {
  return post(MINIIO_UPLOAD_PATH, {
    data
  })
}

/**
 * @desc miniIo 文件下载
 * @param filePath
 */
export function miniIoDownload(filePath: string) {
  return get(`${commonUrl}/minio/file-portal/miniIoDownload`, {
    params: {
      filePath
    },
    responseType: "blob"
  })
}

/**
 * Desc
 * @param filePath
 */
export function miniIoPreview(filePath: any) {
  return get(MINIIO_PREVIEW_PATH, {
    params: {
      filePath
    },
    responseType: "blob"
  })
}


/**
 * @desc 获取后台的导航配置
 */
export function getGlobalNav() {
  return get(`${commonUrl}/portal-menu-publish-config/tree`)
}

/**
 * @desc 获取后台的全局变量设置
 */
export function getGlobalSetting() {
  return get(`${commonUrl}/portal-global-variable/view`)
}

export function miniIoDownloadBatch(filePaths) {
  return post(`${commonUrl}/minio/file-portal/miniIoMultiDownload`, {
    data: {
      filePaths: filePaths
    },
    responseType: "blob"
  })
}