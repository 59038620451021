import { get, post } from "@/utils/request";

const commonUrl = process.env.REACT_APP_URL;

const ssoBase = `${commonUrl}`;
const sysBase = "/szl-center-auth/admin/sys";

interface System {
  appUrl: string;
  id: number;
  name: string;
  source: string;
  webUrl: string;
}

/**
 * 登录
 * @param data
 */
export function login(data: any) {
  return post(`${ssoBase}/login`, {
    data,
  });
}

/**
 * 获取手机验证码
 * @param data
 */
export function getAuthCode(data: any) {
  return post(`${ssoBase}/authcode`, {
    params: data,
    useSelfDead: true,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    skipError: true,
  });
}
export function checkAccount(phone: string) {
  return post(`${ssoBase}/authorized/check-unique-phone`, {
    data: {
      phone,
      registrationPlatform: 1,
    },
  });
}
export function makeAuthorized(data: any) {
  return post(`${ssoBase}/authorized`, {
    data,
  });
}
export function makeAuthorizedAfterLogin() {
  return post(`${ssoBase}/ac/authorized`, {});
}

/**
 * 登录
 * @param data
 */
export function registry(data: any) {
  return post(`${ssoBase}/registry`, {
    data,
  });
}

/**
 * 忘记密码
 * @param data
 */
export function recoverPassword(data: any) {
  return post(`${ssoBase}/recover-password`, {
    data,
  });
}

export interface ResetPasswordParams {
  newPassword: string;
  newPassword2: string;
}

/** 重置密码  */
export function resetPassword(data: ResetPasswordParams) {
  return post(`${commonUrl}/ac/reset-password`, {
    data,
  });
}

export function logout() {
  return post(`${ssoBase}/logout`, {});
}

export function userInfo() {
  return post(`${ssoBase}/sso/userinfo`, {});
}

export function checkUnique(data: {
  excludeCurrentAccount: boolean;
  filedName: "username" | "phone" | "email";
  filedValue: string;
}) {
  return post(`${ssoBase}/check-unique`, { data });
}

/**
 * @description getCaptcha
 * @return
 */
export function getCaptcha() {
  return get(`${ssoBase}/captcha?type=spec`);
}

export function checkUserInfo(authCode: string) {
  return post(`${ssoBase}/ac/verify-authcode`, {
    params: {
      authCode,
    },
  });
}

/**
 * @desc 获取注册时需要的协议-协议来源(1,数据要素平台，2运营平台)
 */
export function getAgreementByRegister() {
  return get(`${commonUrl}/yy-agreement/registry/sign/1`);
}
